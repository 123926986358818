import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IncomeRoutingModule } from './income-routing.module';
import { SharedModule } from '../shared/shared.module';

import { OtherIncomeComponent } from './other-income/other-income.component';
import { PopulationComponent } from './population/population.component';
import { ShipLeasesComponent } from './ship-leases/ship-leases.component';
import { ShipSalesComponent } from './ship-sales/ship-sales.component';
import { TradeComponent } from './trade/trade.component';


@NgModule({
  declarations: [
    OtherIncomeComponent,
    PopulationComponent,
    ShipLeasesComponent,
    ShipSalesComponent,
    TradeComponent,
  ],
  imports: [
    CommonModule,
    IncomeRoutingModule,
    SharedModule,
  ]
})
export class IncomeModule { }
