import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticated } from '../auth/auth.guard';
import { OtherIncomeComponent } from './other-income/other-income.component';
import { PopulationComponent } from './population/population.component';
import { ShipLeasesComponent } from './ship-leases/ship-leases.component';
import { ShipSalesComponent } from './ship-sales/ship-sales.component';
import { TradeComponent } from './trade/trade.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [authenticated],
    children: [
      {
        path: 'population',
        component: PopulationComponent,
      },
      {
        path: 'trade',
        component: TradeComponent,
      },
      {
        path: 'ship-lease',
        component: ShipLeasesComponent,
      },
      {
        path: 'ship-sales',
        component: ShipSalesComponent,
      },
      {
        path: 'other-income',
        component: OtherIncomeComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IncomeRoutingModule { }
