import { Component } from '@angular/core';
import { SimpleListComponent } from '../../shared/simple-list/simple-list.component';

@Component({
  selector: 'ship-sales',
  templateUrl: '../../shared/simple-list/simple-list.component.html',
})
export class ShipSalesComponent extends SimpleListComponent {
  override pageTitle: string = 'Ship Sale Income';
  override listKey: string = "shipSale";
  override displayType: string = 'Ship';
};
