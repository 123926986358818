import { Component } from '@angular/core';
import { SimpleListComponent } from '../../shared/simple-list/simple-list.component';

@Component({
  selector: 'ship-leases',
  templateUrl: '../../shared/simple-list/simple-list.component.html',
})
export class ShipLeasesComponent extends SimpleListComponent {
  override pageTitle: string = 'Lease Income';
  override listKey: string = "leaseIncome";
  override displayType: string = 'Ship';
}
