<div id="_Simple-List-Header_" class="iBTTiny:mx-auto md:m-0 iBTTiny:w-11/12 md:w-full flex flex-col">
  <div class="ism-gold font-bold text-1.5">{{pageTitle}} </div>

  <div id="_simple-list-center_" class="iBTTiny:w-full md:w-1/2 lg:w-1/3 flex flex-col justify-end">
    <div class="my-2 font-bold text-right">Grand Total: {{this.total() | number:"1.2-2" }} MC</div>
    @if(transactions().length > 0) {
    <table class="w-full border">
      <tr class="data">
        @if(displayType === 'Body') {
        <th class="ism-gold border">Location</th>
        <th class="text-right ism-gold border ">Income</th>
        }
        @else {
        <th class="text-left ism-gold">Ship / Description</th>
        <th class="text-right ism-gold border ">Cost</th>
        }
      </tr>
      @for(transaction of transactions(); track transaction;) {
      <tr class="data">
        @if(displayType === 'Body') {
        <td class="text-left">{{transaction.locator}}</td>
        }
        @if(displayType === 'Ship') {
        <td class="text-left">
          @if(transaction.shipId) {
          {{shipNames[transaction.shipId]}}
          }
          @else {
          {{transaction.description}}
          }
        </td>
        }
        <td class="text-right">{{transaction.amount | number:"1.2-2" }} MC</td>
      </tr>
      }
    </table>
    }
    @else {
      <div>No items for this list</div>
    }
  </div>
</div>
