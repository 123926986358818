import { Component } from '@angular/core';
import { SimpleListComponent } from '../../shared/simple-list/simple-list.component';

@Component({
  selector: 'population',
  templateUrl: '../../shared/simple-list/simple-list.component.html',
})
export class PopulationComponent extends SimpleListComponent {
  override pageTitle: string = 'Population Income';
  override listKey: string = "popIncome";
  override displayType: string = 'Body';
}
